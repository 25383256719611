import React from "react";

const Error = () => {
  return (
    <div>
      <h1>Error</h1>
      <button>Back to Home</button>
    </div>
  );
};

export default Error;
